/* src/app/Assets/Styles/GhgSat/theme/_typography.scss  */
// Copyright 2022 GHGSat inc.
// Authors: spectra@ghgsat.com
// This software is not for distribution outside GHGSat organization

// HEADINGS
// H-size non-semantic elements
// TODO: mixin version
._h2,
._h3,
._h4 {
  font-family: var(--font-family-narrow);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--tracking-fit);
}

._h2 {
  font-size: var(--font-size__1);
}

._h3 {
  font-size: var(--font-size__0);
}

._h4 {
  font-size: var(--font-size_-1);
}

._h5 {
  font-family: var(--font-family);
  font-size: var(--font-size_-2);
  font-weight: var(--font-weight-bold);
}

h1,
h2,
h3,
h4 {
  font-family: var(--font-family-narrow);
}

h1 {
  font-size: 3.375rem;
  font-weight: var(--font-weight-normal);
}

h2 {
  font-size: 2.375em;
  font-weight: var(--font-weight-bold);
}

h3 {
  font-size: 1.375em;
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
}

h4 {
  margin: 0.5rem 0;
  font-size: 1em;
  font-weight: var(--font-weight-bold);
}

.bold {
  font-weight: var(--font-weight-bold);
}

.no-wrap {
  white-space: nowrap;
}

.g-link {
  color: var(--color-primary);
  text-decoration: underline;

  &:hover {
    color: var(--color-primary-darker);
  }
}

._utc {
  font-size: inherit;
  font-style: normal;
  font-variant-caps: all-small-caps;
}

.label {
  font-size: var(--font-size_-1);
  font-weight: var(--font-weight-medium);
}
