/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800&display=swap');

.public-page {
  height: 100%;

  .form-title {
    font-family: var(--font-family-montserrat);
    font-size: 1rem;
  }

  .left {
    display: flex;
    position: fixed;
    width: 53%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    background-image: url('../../assets/images/bg-login.jpg');
    background-position: center left;
    background-size: cover;

    a {
      text-decoration: underline;
    }
  }

  .keep-logged-in {
    padding: 10px;
    font-size: var(--font-size_-1);

    label {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    input[type="checkbox"] {
      width: 24px;
      height: 24px;
    }
  }

  .right {
    position: relative;
    min-height: 100vh;
    margin-left:53%;
    background-color: var(--color-white);
    color: var(--color-orbit-blue);
    display: flex;
    flex-direction: column;
    justify-content: center;


    input {
      background-color: var(--color-grey--100);
    }


    input{
      background-color: var(--color-grey--100);
    }


    .public-page-header {
      padding-top: 100px;

      .public-page-logo {
        padding: 4rem 3rem 3rem 3rem;
        display: flex;
        justify-content: center;

        img {
          max-width: 280px;
        }
      }
    }

    .public-page-form {
      align-self: center;
      justify-self: center;
      font-family: var(--font-family-montserrat);


      .g-label {
        color: var(--color-orbit-blue);
      }

      .errors-container {
        display: flex;
        min-height: 40px;
        align-items: center;
        color: var(--color-red);
      }
    }
  }

  .public-page-footer {
    position: absolute;
    right: 2rem;
    bottom: 1rem;

    .disclosure {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .powered-by {
      transform: translateY(2px);
      margin-right: 10px;
      color: var(--color-grey--600);
      font-size: var(--font-size_-2);
    }
  }

  @media screen and (max-width: 950px){
    .right {
      height: 100%;
      margin-left: 0;
      margin-right: auto;
      overflow: hidden;

      .public-page-header{
        .public-page-logo {
          margin-top: -7rem;
          padding: 2rem 3rem 3rem 3rem;
         
        }
      }
    }
  }
}
