/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

.resend-code-container {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0.5rem;

  .basic-button.g-link-button {
    text-decoration: none;
    font-weight: bold;
    border-bottom: 1px solid var(--color-primary);
  }
}