/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

.forgot-password {
  display: flex;
  flex-direction: column;
  align-items: center;

  .forgot-title {
    text-align: center;
    margin-top: 2rem;
    font-size: var(--font-size__0);
    font-family: var(--font-family-montserrat);
  }

  .instructions {
    margin-top: 1rem;
    text-align: center;
    width: 20rem;
  }

  .step {
    margin-top: 1rem;
  }

  form {
    width: 21rem;
  }

  .g-text-field {
    display: flex;
    justify-content: center;
  }

  .submit-container {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .icon-right {
    position: absolute;
    right: -2rem;
    top: 0.8rem;
  }

  .info-icon {
    height: 16px;
    width: 16px;
  }

  .field-and-tooltip {
    position: relative;
  }

  .submit-container button {
    width: 13rem;
    padding: 10px;
    display: flex;
    justify-content: center;
    border-radius: 100px;
    font-family: var(--font-family-montserrat);
  }


  .public-divider {
    width: 310px;
    height: 0;
    margin: 20px 0;
    justify-self: center;
    color: var(--color-grey--500);
  }

  .create-account-div {
    margin-left: 5px;
    width: 400px;
    display: flex;
    min-height: 120px;
    flex-direction: column;
    justify-content: space-between;

    .create-account-link {
      font-size: var(--font-size_-1);
      font-family: var(--font-family-montserrat);
    }

  }
}

@media screen and (max-width: 800px) {
  .form-titles-container {
    width: 19rem;
  }

  .form-title-container{
    .new-sticker{
      padding: 4px 6px;
      right: -2rem;
    }
  }

  .forgot-password {
    font-size: 12px;

    form {
      width: 16rem;
    }
  }
}