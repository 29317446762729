/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

 .create-account-form {
  padding-bottom: 3rem;

  .details {
    margin-left: 1rem;
    margin-top: 2rem;
    font-size: var(--font-size__0);
    font-family: var(--font-family-montserrat);
    font-weight: normal;
  }

  .drop-down {
    font-family: var(--font-family-montserrat);
  }
 }
 