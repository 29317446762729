/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

.basic-button {
  cursor: pointer;

  &.g-button {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid var(--color-primary);
    border-radius: 5px;
    font-weight: 700;
    font-size: var(--font-size__0);
    color: var(--color-primary);

    &.primary {
      border: none;
      background-color: var(--color-primary);
      color: var(--surface-a);
    }

    &.disabled {
      border: none;
      background-color: var(--color-grey--500);
      color: var(--color-primary-text);
    }

    .g-loading-spinner {
      circle {
        stroke: var(--color-primary);
      }
    }

    .g-button-content {
      padding-left: 8px;
    }
  }

  &.g-link-button {
    color: var(--color-primary);
    text-decoration: underline;

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  }
}
