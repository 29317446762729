/*!
 *
 *  * Copyright 2022 GHGSat inc.
 *  * Authors: spectra@ghgsat.com
 *  * This software is not for distribution outside GHGSat organization
 *
 */

.p-fluid {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.p-field {
  padding-block: 0.5rem;
}
