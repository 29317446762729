/**
 * /*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 * @format
 */

.create-account-errors-container {
  min-height: 2.5rem;

  .p-error {
    text-align: left;
    color: var(--color-error);
    font-size: var(--font-size_-1);
    padding: 10px;
  }
}

@media screen and (max-width: 800px) {
  .create-account-errors-container{
    .p-error{
      font-size: 11px;
    }
  }
}