/*!
 *
 *  * Copyright 2022 GHGSat inc.
 *  * Authors: spectra@ghgsat.com
 *  * This software is not for distribution outside GHGSat organization
 *
 */

.create-account {
  display: flex;
  flex-direction: column;
  align-items: center;

  .instructions {
    margin-top: 1rem;
    text-align: center;
    width: 22.4rem;
  }

  .instructions-link { 
    text-align: center;
    font-size: var(--font-size_-2);
    margin: 1rem auto 1rem auto;
    width: 18rem;
  }

  .step {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  form {
    width: 21rem;
  }

  .g-select-field {
    padding-left: 1rem;
    border-radius: 100px;
    height: 2.5rem;
    width: 100%;
    border: 1px solid var(--color-primary);
    background-color: var(--color-white);
  }

  .g-text-field {
    display: flex;
    justify-content: center;
    //width: 18rem !important;
  }

  .submit-container {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;


  }

  .icon-right {
    position: absolute;
    right: -2rem;
    top: 0.8rem;
  }

  .info-icon {
    height: 16px;
    width: 16px;
  }

  .field-and-tooltip {
    position: relative;
  }

  .submit-container button {
    width: 13rem;
    padding: 10px;
    display: flex;
    justify-content: center;
    border-radius: 100px;
    font-family: var(--font-family-montserrat);
  }


  .public-divider {
    width: 310px;
    height: 0;
    margin: 20px 0;
    justify-self: center;
    color: var(--color-grey--500);
  }

  .create-account-div {
    margin-left: 5px;
    width: 400px;
    display: flex;
    min-height: 120px;
    flex-direction: column;
    justify-content: space-between;

    .create-account-link {
      font-size: var(--font-size_-1);
      font-family: var(--font-family-montserrat);
    }

  }
}

@media screen and (max-width: 800px) {
  .form-titles-container {
    width: 19rem;
  }
  .form-title-container{
    .new-sticker{
    padding: 4px 6px;
    right: -1.7rem;
  }
}

 .create-account{
  font-size: 12px;

  form {
    width: 16rem;
  }

  .instructions {
    width: 16rem;
    margin: auto;
  }
  .instructions-link{
    width: 16rem;
    margin: 0.5rem auto 0.5rem auto;
  }
 }
}
