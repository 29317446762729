/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

.g-modal-root {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  z-index: 9998;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);

  .g-modal-window {
    margin: 1em;
    background-color: var(--surface-a);

    .g-modal-window-header {
      padding: 1em;
      background-color: var(--surface-g);
    }
  }

  &.p-dialog .p-dialog-header {
    display: grid;
    height: var(--dialog-header-height);
    align-content: center;
    background-color: var(--color-grey-disabled);

    .p-button-outlined {
      position: absolute;
      top: 0.5em;
      right: 2em;
      text-transform: uppercase;
    }

    .p-dialog-header-icons {
      display: none;
    }
  }
}
