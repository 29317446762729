/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

.schedule-maintenance-warning {
  position: absolute;
  top: 0;
  width: 100%;
  padding-bottom: 2rem;
  border-top: var(--color-red-maintenance) 5px solid;
  background-color: var(--surface-maintenance);
  color: var(--color-primary);
  text-align: center;
  font-family: var(--font-family-montserrat);

  .header {
    padding: 12px 14px 0 14px;

    .title {
      font-size: var(--font-size__0);
    }
  }

  .content {
    text-align: center;
    padding: 0 14px;

    svg {
      padding-right: 16px;

      path {
        fill: var(--color-yellow);
      }
    }
  }

  .icon {
    margin-right: 10px;
    color: var(--color-yellow);

    &::before {
      margin: 0;
    }
  }

  .info {
    font-size: var(--font-size__0);
    line-height: 18px;
  }
}