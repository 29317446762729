/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

.modal-body {
  padding: 1rem 2rem;
  color: var(--color-primary);
}
