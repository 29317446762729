/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

.modal-footer-buttons {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 5px 0;
  box-shadow: 1px 1px 7px 0 var(--color-black-shadow);

  > * {
    margin: 5px;
  }
}
