/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

.login {
  display: flex;
  flex-direction: column;
  align-items: center;

  .customer-login-prompt {
    font-size: var(--font-size_-2);
    margin-top: 1rem;

    .login-here {
      color: var(--color-primary);
    }
  }

  #keep-logged-in-lbl{
    font-size: var(--font-size__0);
  }
  .forgot-password-link{
    margin-left:1rem;
    font-size: var(--font-size_-2);
  }

  form {
    width: 21rem;
  }

  .submit-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .submit-container button {
    width: 13rem;
    padding: 10px;
    display: flex;
    justify-content: center;
    border-radius: 100px;
    font-family: var(--font-family-montserrat);
  }


  .public-divider {
    width: 310px;
    height: 0;
    margin: 20px 0;
    justify-self: center;
    color: var(--color-grey--500);
  }

  .create-account-div {
    margin-left: 5px;
    width: 400px;
    display: flex;
    min-height: 120px;
    flex-direction: column;
    justify-content: space-between;

    .create-account-link {
      font-size: var(--font-size_-1);
      font-family: var(--font-family-montserrat);
    }

  }

  @media screen and (max-width: 800px) {

    .form-titles-container {
      width: 19rem;
    }
    .new-sticker{
      padding: 5px 6px;
      right: -1.7rem;
    }

   form {
    width: 16rem;
   }
  }
}
