/*!
 *
 *  * Copyright 2022 GHGSat inc.
 *  * Authors: spectra@ghgsat.com
 *  * This software is not for distribution outside GHGSat organization
 *
 */

.form-titles-container {
  display: flex;
  justify-content: space-between;

  a {
    text-decoration: none;
  }
}

.form-title-container.not-selected {
  opacity: 0.4;
}

.form-title-container {
  width: 10.25rem;
  text-align: center;
  position: relative;
  padding-right: 1rem;

  .new-sticker {
    background-color: var(--color-new-underscore);
    padding: 5px 12px;
    color: var(--color-white);
    position: absolute;
    right: -2.5rem;
    top: 0.35em;
    font-family: var(--font-family-montserrat);
    font-size: 12px;
  }
}

.form-title-underscore {
  color: var(--color-primary);
  border-style: solid;
}

.form-title-underscore.new {
  color: var(--color-new-underscore);
}