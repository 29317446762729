/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

 .g-spinner {
  @keyframes rotateCircle {
    0% {
      transform: rotate(267deg);
    }
    25% {
      transform: rotate(360deg);
    }
    25.1% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(93deg);
    }
    75% {
      transform: rotate(183deg);
    }
    100% {
      transform: rotate(266deg);
    }
  }
  circle {
    transform-origin: 16px 16px;
    animation-name: rotateCircle;
    animation-play-state: running;
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-duration: 1s;
  }
 }
