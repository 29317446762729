/*!
 *
 *  * Copyright 2022 GHGSat inc.
 *  * Authors: spectra@ghgsat.com
 *  * This software is not for distribution outside GHGSat organization
 *
 */

.uppercase {
  font-family: var(--font-family-montserrat);
  border-radius: 100px !important;
  padding: 10px 17px !important;
}

.g-modal-window-header {
  font-weight: bold;
  font-family: var(--font-family-montserrat);
  color: var(--color-primary);
}